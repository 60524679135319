'use strict';

export default ({ app }, inject) => {
    inject('getCategoryBreadcrumb', categories => {
        if (!categories?.length) {
            return [];
        }

        const [category] = categories;
        const page = category.parentCategoryId ? 'subcategories' : 'categories';

        return [
            {
                text: category.name,
                to: app.localePath(`/${page}/${category.slug}`)
            }
        ];
    });
};
