<template>
    <component
        :is="isInternalLink ? 'nuxt-link' : 'a'"
        v-bind="linkComponentOptions"
        :class="classNames"
    >
        <slot>{{ link.text }}</slot>
    </component>
</template>

<script>
export default {
    props: {
        link: {
            type: Object,
            required: true
        },

        classNames: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        isInternalLink() {
            return this.link.redirect.startsWith('/');
        },

        linkComponentOptions() {
            const { redirect, blank } = this.link;

            const redirectObj = this.isInternalLink
                ? { to: this.localePath(redirect) }
                : { href: redirect };

            return {
                ...redirectObj,
                ...(blank && {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                })
            };
        }
    }
};
</script>
