'use strict';

export default (ctx, inject) => {
    inject('getArticleLink', (article, parameters = {}) => {
        const id = article.slug || article.numericId || article.id;

        const keys = Object.keys(parameters);
        const keyValuePairs = keys.map(
            key =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                    parameters[key]
                )}`
        );
        const queryParams = `?${keyValuePairs.join('&')}`;

        return ctx.app.localePath(
            `/articles/${id}${keyValuePairs.length ? queryParams : ''}`
        );
    });
};
